<div class="flex justify-between items-center">
  <div class="flex items-center">
    <img src="../../../../../assets/images/logo-text.png" alt="Autotaxatie Partners" class="m-2 mr-12 h-16">

    <div class="dropdown relative inline-block" *ngFor="let menu of menuService.headerMenu[0].items">
      <button
        (click)="navigateTo(menu.route)"
        [routerLink]="menu.route"
        routerLinkActive="bg-gray-200 text-gray-900"
        [ngClass]="menu.active ? 'bg-green-500 text-white' : 'text-gray-600 hover:bg-gray-100 hover:text-primary-500 dark:text-night-200 dark:hover:bg-night-600'"
        class="mr-2 inline-flex rounded-md px-3 py-2 text-lg font-medium">
        <span>{{ menu.label }}</span>
      </button>
    </div>
  </div>

  <div class="flex flex-col items-end text-sm text-primary-500 space-y-1" *ngIf="authUserInformation !== null">
    <!-- Company Name -->
    <span class="font-semibold">
      {{ authUserInformation.Company.name }}
    </span>
  
    <!-- User Name with SVG -->
    <span class="flex items-center">
      <!-- Inline SVG -->
      <svg class="w-4 h-4 mr-2 text-gray-500" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M16 7a4 4 0 1 1-8 0 4 4 0 0 1 8 0zm6 14a10 10 0 1 0-20 0h20z" />
      </svg>
      <!-- User Name -->
      {{ authUserInformation.Person.firstname }}
      {{ authUserInformation.Person.infix }}
      {{ authUserInformation.Person.lastName }}
    </span>
  
    <!-- Logout Action -->
    <span class="text-green-500 hover:text-green-700 cursor-pointer transition duration-150" (click)="logout()">
      Uitloggen
    </span>
  </div>
  
</div>